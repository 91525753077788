import { RootState } from "@/store";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className }) => {
  const navigate = useNavigate();
  const authSession = useSelector((state: RootState) => state.auth.inSession);
  return (
    <svg
      className={className}
      onClick={() => {
        if (authSession) {
          navigate({
            pathname: "/teams",
          });
        
        } else
        window.open("https://bonadocs.com/", "_blank");
      }}
      width="118"
      height="24"
      viewBox="0 0 118 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.1804 8.7076C35.7813 8.40939 36.4447 8.2587 37.1155 8.26802C37.9136 8.25755 38.6993 8.46575 39.3874 8.87005C40.0702 9.27886 40.6241 9.87164 40.9857 10.5806C41.3859 11.3655 41.5869 12.2368 41.571 13.1177C41.5858 14.0046 41.3849 14.8818 40.9857 15.6739C40.6227 16.3962 40.066 17.0033 39.3779 17.4274C38.7451 17.8023 38.0301 18.0163 37.2954 18.0509C36.5608 18.0855 35.8289 17.9395 35.1637 17.6257C34.6335 17.3698 34.171 16.9925 33.8139 16.5244V17.9004H31.8525V5.17188H33.8139V9.83281C34.1719 9.35291 34.6407 8.96684 35.1804 8.7076ZM39.1724 11.4239C38.9244 10.9706 38.5545 10.5957 38.1045 10.3417C37.6716 10.0973 37.1826 9.96968 36.6855 9.97137C36.193 9.97178 35.7093 10.102 35.2831 10.3488C34.8336 10.6111 34.4649 10.9922 34.2176 11.4502C33.9379 11.9734 33.7988 12.5604 33.8139 13.1535C33.7997 13.7489 33.9388 14.3379 34.2176 14.864C34.4544 15.3134 34.8094 15.6895 35.2443 15.9518C35.6793 16.2141 36.1776 16.3526 36.6855 16.3524C37.1847 16.3537 37.6751 16.2199 38.1045 15.9654C38.5571 15.6956 38.9266 15.3062 39.1724 14.8401C39.4497 14.3091 39.5886 13.7166 39.5762 13.1177C39.5905 12.5276 39.4514 11.944 39.1724 11.4239Z"
        fill="#E2E7EF"
      />
      <path
        d="M45.2002 17.4437C44.4822 17.0416 43.8919 16.4455 43.4968 15.7236C43.0726 14.9344 42.8594 14.0489 42.8781 13.153C42.8611 12.2573 43.0812 11.373 43.5159 10.5897C43.9203 9.86339 44.5226 9.26688 45.2527 8.86958C46.0122 8.47408 46.8559 8.26758 47.7122 8.26758C48.5685 8.26758 49.4122 8.47408 50.1717 8.86958C50.9018 9.26688 51.5041 9.86339 51.9085 10.5897C52.3433 11.373 52.5633 12.2573 52.5463 13.153C52.5625 14.0508 52.3364 14.9363 51.8918 15.7164C51.4713 16.447 50.8548 17.0454 50.112 17.4437C49.3482 17.8549 48.4924 18.0654 47.625 18.0553C46.7771 18.0671 45.941 17.8562 45.2002 17.4437ZM49.0441 15.9912C49.4978 15.7386 49.8706 15.3625 50.1191 14.9066C50.4095 14.3689 50.5521 13.7638 50.5324 13.153C50.552 12.5461 50.4164 11.9442 50.1382 11.4043C49.8976 10.953 49.5329 10.58 49.0871 10.3293C48.6561 10.0898 48.1706 9.96555 47.6776 9.96851C47.1867 9.96461 46.7033 10.089 46.2752 10.3293C45.8362 10.5794 45.4806 10.9533 45.2527 11.4043C44.9845 11.9463 44.854 12.5462 44.8729 13.1507C44.8729 14.17 45.1341 14.9583 45.6565 15.5158C45.906 15.7873 46.2108 16.0023 46.5503 16.1461C46.8899 16.2899 47.2564 16.3593 47.625 16.3495C48.1209 16.3532 48.6094 16.2299 49.0441 15.9912Z"
        fill="#E2E7EF"
      />
      <path
        d="M61.273 8.73161C61.8631 9.04008 62.3461 9.51976 62.6586 10.1077C63.008 10.7881 63.1797 11.5458 63.1579 12.3103V17.9006H61.2133V12.5994C61.2133 11.7521 61.0015 11.1015 60.5778 10.6476C60.3529 10.4179 60.0817 10.2387 59.7823 10.1217C59.4829 10.0048 59.1621 9.95275 58.841 9.9691C58.5186 9.95306 58.1964 10.0052 57.8955 10.1221C57.5946 10.239 57.3217 10.4181 57.0947 10.6476C56.6647 11.1015 56.4496 11.7521 56.4496 12.5994V17.8982H54.4883V8.42343H56.4496V9.50564C56.781 9.10812 57.2029 8.79597 57.68 8.59543C58.1817 8.37665 58.7237 8.26517 59.2711 8.26814C59.9662 8.25684 60.6536 8.41596 61.273 8.73161Z"
        fill="#E2E7EF"
      />
      <path
        d="M65.5927 10.5909C65.9613 9.88443 66.5181 9.29364 67.2016 8.88384C67.885 8.47404 68.6684 8.26118 69.4652 8.26877C70.1421 8.25245 70.8125 8.40345 71.417 8.70836C71.9363 8.96978 72.3915 9.34257 72.7501 9.80013V8.42406H74.7282V17.9012H72.7501V16.4917C72.3889 16.9629 71.9248 17.3454 71.3931 17.6097C70.785 17.9148 70.112 18.0681 69.4318 18.0565C68.6419 18.0631 67.8665 17.8452 67.1957 17.4282C66.5104 17.0006 65.9546 16.3944 65.5879 15.6747C65.1822 14.8845 64.9779 14.0065 64.993 13.1184C64.9772 12.2389 65.1835 11.3696 65.5927 10.5909ZM72.3511 11.4509C72.1058 10.9938 71.7397 10.6129 71.2928 10.3496C70.865 10.1003 70.3784 9.97001 69.8833 9.97213C69.3891 9.96977 68.903 10.0975 68.4738 10.3424C68.0276 10.5983 67.6613 10.9729 67.4155 11.4246C67.1355 11.9444 66.9963 12.5283 67.0117 13.1184C66.9982 13.7175 67.1371 14.3102 67.4155 14.8409C67.66 15.3071 68.0288 15.6966 68.481 15.9661C68.905 16.2191 69.3895 16.3528 69.8833 16.3531C70.3784 16.3553 70.865 16.2249 71.2928 15.9757C71.7412 15.71 72.1074 15.3255 72.3511 14.8648C72.63 14.3386 72.769 13.7496 72.7549 13.1543C72.77 12.5611 72.6308 11.9742 72.3511 11.4509Z"
        fill="#E2E7EF"
      />
      <path
        d="M77.2531 10.5901C77.6245 9.88072 78.1852 9.28815 78.8731 8.87823C79.5609 8.46831 80.3489 8.25708 81.1496 8.26802C81.782 8.27173 82.4065 8.40933 82.9819 8.67176C83.5394 8.90938 84.0311 9.27819 84.4153 9.74681V5.17188H86.3934V17.9004H84.4153V16.467C84.0709 16.9527 83.6134 17.3471 83.0823 17.6162C82.4777 17.9217 81.8077 18.075 81.1305 18.0629C80.3323 18.0702 79.5482 17.8524 78.8681 17.4346C78.1788 17.0089 77.6194 16.4024 77.2507 15.6811C76.8469 14.8904 76.6434 14.0126 76.6583 13.1248C76.6408 12.2434 76.8454 11.3718 77.2531 10.5901ZM84.014 11.4502C83.768 10.9936 83.402 10.6128 82.9556 10.3488C82.5279 10.0996 82.0412 9.96925 81.5461 9.97137C81.0512 9.96904 80.5643 10.0967 80.1342 10.3417C79.6886 10.5982 79.3224 10.9727 79.0759 11.4239C78.797 11.944 78.6579 12.5276 78.6722 13.1177C78.6598 13.7166 78.7986 14.3091 79.0759 14.8401C79.3217 15.3062 79.6912 15.6956 80.1438 15.9654C80.5676 16.2189 81.0523 16.3527 81.5461 16.3524C82.0412 16.3545 82.5279 16.2242 82.9556 15.9749C83.4035 15.7085 83.7696 15.3242 84.014 14.864C84.2929 14.3379 84.4319 13.7489 84.4177 13.1535C84.4328 12.5604 84.2937 11.9734 84.014 11.4502Z"
        fill="#E2E7EF"
      />
      <path
        d="M90.6626 17.4437C89.9436 17.0429 89.3529 16.4465 88.9592 15.7236C88.533 14.935 88.319 14.0492 88.3381 13.153C88.3203 12.2575 88.5395 11.3732 88.9736 10.5897C89.3779 9.86339 89.9802 9.26688 90.7104 8.86958C91.4699 8.47408 92.3135 8.26758 93.1698 8.26758C94.0261 8.26758 94.8698 8.47408 95.6293 8.86958C96.3596 9.26748 96.9625 9.86378 97.3685 10.5897C97.8015 11.3736 98.0206 12.2576 98.004 13.153C98.0212 14.0509 97.795 14.9367 97.3494 15.7164C96.929 16.447 96.3124 17.0454 95.5696 17.4437C94.8067 17.8549 93.9517 18.0654 93.085 18.0553C92.2379 18.0674 91.4025 17.8565 90.6626 17.4437ZM94.5065 15.9912C94.9596 15.7379 95.3322 15.362 95.5815 14.9066C95.8708 14.3685 96.0133 13.7637 95.9948 13.153C96.0133 12.5467 95.8768 11.9456 95.5982 11.4067C95.3578 10.956 94.994 10.5832 94.5495 10.3316C94.1178 10.0918 93.6314 9.96753 93.1376 9.9709C92.6475 9.96697 92.1648 10.0913 91.7376 10.3316C91.2984 10.5823 90.9422 10.956 90.7128 11.4067C90.4453 11.949 90.3157 12.5488 90.3353 13.153C90.3353 14.1723 90.5957 14.9607 91.1165 15.5181C91.3666 15.7895 91.6719 16.0043 92.0118 16.1481C92.3517 16.2919 92.7184 16.3614 93.0874 16.3519C93.5834 16.3544 94.0719 16.2302 94.5065 15.9912Z"
        fill="#E2E7EF"
      />
      <path
        d="M99.8888 10.581C100.262 9.86583 100.833 9.27236 101.532 8.87048C102.268 8.46009 103.099 8.25233 103.941 8.26845C105.087 8.26845 106.036 8.54079 106.786 9.08548C107.555 9.6564 108.096 10.4832 108.31 11.4171H106.193C106.041 10.9719 105.752 10.5864 105.367 10.3158C104.942 10.0376 104.441 9.899 103.933 9.91925C103.571 9.90579 103.21 9.97568 102.879 10.1235C102.547 10.2714 102.254 10.4933 102.022 10.7721C101.544 11.3375 101.305 12.1338 101.305 13.1611C101.305 14.1884 101.544 14.9847 102.022 15.5501C102.253 15.8307 102.546 16.0542 102.877 16.2034C103.209 16.3525 103.57 16.4233 103.933 16.4101C105.069 16.4101 105.82 15.9108 106.186 14.9122H108.303C108.058 15.8293 107.513 16.6377 106.755 17.2081C105.934 17.7963 104.942 18.0962 103.933 18.0609C103.09 18.0774 102.258 17.8661 101.525 17.4494C100.826 17.0431 100.256 16.4466 99.8816 15.7293C99.4917 14.928 99.2891 14.0486 99.2891 13.1575C99.2891 12.2664 99.4917 11.387 99.8816 10.5858L99.8888 10.581Z"
        fill="#E2E7EF"
      />
      <path
        d="M111.707 17.6522C111.153 17.4115 110.67 17.0322 110.305 16.5509C109.966 16.1002 109.773 15.5567 109.753 14.9933H111.784C111.801 15.1927 111.861 15.3859 111.961 15.5596C112.06 15.7333 112.196 15.8832 112.359 15.999C112.74 16.2791 113.204 16.4222 113.676 16.4052C114.125 16.4314 114.572 16.3197 114.956 16.085C115.096 15.9994 115.211 15.8793 115.291 15.7362C115.371 15.5931 115.413 15.4319 115.412 15.268C115.416 15.1008 115.37 14.9362 115.28 14.7953C115.19 14.6545 115.059 14.5437 114.906 14.4772C114.388 14.2407 113.85 14.0512 113.298 13.911C112.708 13.7555 112.127 13.5656 111.559 13.3425C111.11 13.1579 110.712 12.8688 110.398 12.4992C110.057 12.0774 109.883 11.5453 109.908 11.0036C109.907 10.5062 110.063 10.0211 110.355 9.61803C110.683 9.17653 111.127 8.83427 111.638 8.62898C112.244 8.38014 112.894 8.2574 113.549 8.26824C114.627 8.26824 115.495 8.54058 116.153 9.08527C116.48 9.35739 116.745 9.6975 116.927 10.082C117.11 10.4665 117.207 10.8861 117.211 11.3118H115.25C115.241 11.1161 115.19 10.9246 115.101 10.75C115.012 10.5754 114.887 10.4216 114.734 10.2989C114.374 10.0284 113.93 9.89389 113.48 9.91904C113.061 9.89114 112.643 9.99379 112.285 10.2129C112.156 10.2961 112.051 10.4109 111.978 10.5463C111.906 10.6818 111.869 10.8334 111.872 10.9869C111.87 11.1062 111.894 11.2246 111.941 11.3341C111.988 11.4437 112.059 11.5419 112.147 11.6224C112.341 11.8011 112.569 11.9385 112.818 12.0261C113.082 12.1233 113.473 12.2467 113.989 12.3964C114.565 12.5434 115.131 12.7302 115.682 12.9554C116.122 13.1437 116.513 13.4287 116.827 13.7892C117.163 14.201 117.34 14.7198 117.326 15.2513C117.33 15.7643 117.173 16.2658 116.877 16.6847C116.556 17.1283 116.119 17.4741 115.613 17.6833C115.008 17.9343 114.357 18.0572 113.702 18.044C113.017 18.0575 112.336 17.9239 111.707 17.6522Z"
        fill="#E2E7EF"
      />
      <path
        d="M22.2964 11.9555V11.9818C22.2989 13.9075 21.8323 15.8049 20.9371 17.5099L20.507 18.2649C19.4392 19.9865 17.9493 21.4069 16.1788 22.3914C14.4082 23.376 12.4156 23.8922 10.3896 23.8909V17.9519C11.9737 17.9519 13.4928 17.3227 14.6128 16.2026C15.7329 15.0825 16.3621 13.5634 16.3621 11.9794V11.9531L22.2964 11.9555Z"
        fill="#055DF5"
      />
      <path
        d="M21.2119 0L7.55161 23.8684H0L13.6603 0H21.2119Z"
        fill="#055DF5"
      />
      <path
        d="M20.9179 9.97163H20.7769V9.18326H20.4902V9.05664H21.2069V9.18326H20.9107L20.9179 9.97163Z"
        fill="#E2E7EF"
      />
      <path
        d="M21.7569 9.97163L21.4679 9.27166V9.97163H21.3389V9.05664H21.5109L21.8191 9.79963L22.1248 9.05664H22.2969V9.97163H22.1631V9.27166L21.8764 9.97163H21.7569Z"
        fill="#E2E7EF"
      />
    </svg>
  );
};
